import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import Loading from '../../components/Loading';

import $ from 'jquery';
import { toast } from 'react-toastify';

import '../../Libs/fontawesome/css/all.css';
import { removeAcento } from '../../Util/Functions';

const columns = [
  { title: "Categoria", data: null, render: (data, type, row) => { if (type === 'display') { return row.name } else { return removeAcento(row.name) } } },
  { title: "Ativo" },
  { title: "Ações" },
];

const columnDefs = [
  {
    targets: [1],
    data: null,
    render: function(data, type, row) {
      return row.active ? 'Sim' : 'Não'
    }
  },
  {
    targets: -1,
    data: null,
    defaultContent: '<a class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-course-category"></i></span></a>'
      + '<a class=" action-button"><i class="fas fa-check action-icon toggle-course-category"></i></span></a>'
      + '<a class=" action-button"><i class="far fa-trash-alt action-icon delete-course-category"></i></span></a>'
  }
];


export default class CourseCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courseCategories: [],
      loading: true,
      modalCreateOpen: false
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (update) => {
    let response = await Api.get('/courseCategory');
    this.setState({ courseCategories: response.data.courseCategories, loading: false });
    if (update === true) {
      this.table.reloadTableData(response.data.courseCategories);
    }
  }

  handleSuccess = (message) => {
    this.toggleModal();
    this.getData(true);
    toast.success(message);
  }

  handleError = (message) => {
    toast.error(message);
  }

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents('tr');
    if (current_row.hasClass('child')) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes('delete-course-category')) {
      if (window.confirm(`Deseja excluir a categoria ${data.name}?`)) this.deleteCourseCategory(data.id);
    }
    else if (e.target.className.includes('toggle-course-category')) {
      let stringMessage = `Deseja habilitar a categoria ${data.name}?`;
      if (data.active === true) stringMessage = `Deseja desabilitar a categoria ${data.name}?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.toggleCourseCategory(data.id);
    }
    else if (e.target.className.includes('edit-course-category')) {
      this.openCourseCategoryEditModal(data.id);
    }
  }

  openCourseCategoryEditModal = (courseCategoryId) => {
    this.setState({ courseCategoryId }, this.toggleModal)
  }

  toggleCourseCategory = async (courseCategoryId) => {
    try {
      await Api.put('/courseCategory/toggle', { category_id: courseCategoryId });
      toast.success('Categoria atualizada.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  deleteCourseCategory = async (courseCategoryId) => {
    try {
      await Api.delete('/courseCategory', { data: { category_id: courseCategoryId } });
      toast.success('Categoria excluída.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ courseCategoryId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
        <DataTable
          data={this.state.courseCategories}
          columns={columns}
          options={{
            buttons: [],
            responsive: true,
            columnDefs: columnDefs,
            destroy: true
          }}
          handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
          ref={ref => this.table = ref}
        />
        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          courseCategoryId={this.state.courseCategoryId ? this.state.courseCategoryId : null}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={message => { this.handleSuccess(message) }}
          onError={message => { this.handleError(message) }}
        />
      </div>
    )
  }
}

