import React, { Component } from "react";

import Api from "../../Services/Cloud";
import DataTable from "../../components/DataTable";
import Modal from "./Modal";
import Loading from "../../components/Loading";

import $ from "jquery";
import { toast } from "react-toastify";

import "../../Libs/fontawesome/css/all.css";
import { removeAcento } from "../../Util/Functions";

const columns = [
  {
    title: "Turno",
    data: null,
    render: (data, type, row) => {
      if (type === "display") {
        return row.name;
      } else {
        return removeAcento(row.name);
      }
    },
  },
  { title: "Ativo" },
  { title: "Ações" },
];

const columnDefs = [
  {
    targets: [1],
    data: null,
    render: function (data, type, row) {
      return row.active ? "Sim" : "Não";
    },
  },
  {
    targets: -1,
    data: null,
    defaultContent:
      '<a class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-shift"></i></span></a>' +
      '<a class=" action-button"><i class="fas fa-check action-icon toggle-shift"></i></span></a>' +
      '<a class=" action-button"><i class="far fa-trash-alt action-icon delete-shift"></i></span></a>',
  },
];

export default class Shifts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shifts: [],
      loading: true,
      modalCreateOpen: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (update) => {
    try {
      let response = await Api.get("/shift");
      this.setState({
        shifts: response.data.shifts,
        loading: false,
      });
      if (update === true) {
        this.table.reloadTableData(response.data.shifts);
      }
    } catch (error) {
      console.log(error?.response?.data, "error fetching shifts");
    }
  };

  handleSuccess = (message) => {
    this.toggleModal();
    this.getData(true);
    toast.success(message);
  };

  handleError = (message) => {
    toast.error(message);
  };

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents("tr");
    if (current_row.hasClass("child")) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes("delete-shift")) {
      if (window.confirm(`Deseja excluir o turno ${data.name}?`))
        this.deleteShift(data.id);
    } else if (e.target.className.includes("toggle-shift")) {
      let stringMessage = `Deseja habilitar o turno ${data.name}?`;
      if (data.active === true)
        stringMessage = `Deseja desabilitar o turno ${data.name}?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.toggleShift(data.id);
    } else if (e.target.className.includes("edit-shift")) {
      this.openShiftEditModal(data.id);
    }
  };

  openShiftEditModal = (shiftId) => {
    this.setState({ shiftId }, this.toggleModal);
  };

  toggleShift = async (shiftId) => {
    try {
      await Api.put("/shift/toggle", { shift_id: shiftId });
      toast.success("Turno atualizado.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  deleteShift = async (shiftId) => {
    try {
      await Api.delete("/shift", { data: { shift_id: shiftId } });
      toast.success("Turno excluído.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ shiftId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen });
  };

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <button className="btn btn-primary mb-4" onClick={this.toggleModal}>
          CADASTRAR
        </button>
        <DataTable
          data={this.state.shifts}
          columns={columns}
          options={{
            buttons: [],
            responsive: true,
            columnDefs: columnDefs,
            destroy: true,
          }}
          handleActionClick={(e, dataTable) => {
            this.handleTableClick(e, dataTable);
          }}
          ref={(ref) => (this.table = ref)}
        />
        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          shiftId={this.state.shiftId ? this.state.shiftId : null}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={(message) => {
            this.handleSuccess(message);
          }}
          onError={(message) => {
            this.handleError(message);
          }}
        />
      </div>
    );
  }
}
