import React, { useEffect } from "react";
import AsyncSelect from "react-select/async";
import Api from "../../Services/Cloud";
import { toast } from "react-toastify";

export const SelectInstitution = (props) => {
  const loadInstitutions = async (inputValue) => {
    try {
      const { data } = await Api.get(`/institution?search=${inputValue}`);

      return data.institutions.map((institution) => ({
        value: institution.id,
        label: `${institution.name} - ${institution.city}`,
      }));
    } catch (error) {
      console.log(error, "erro ao buscar instituições");
      toast.error(`Erro ao buscar instituições`);
    }
  };

  useEffect(() => {
    loadInstitutions();
  }, []);

  return (
    <AsyncSelect
      placeholder={"Busque uma instituição"}
      noOptionsMessage={(inputValue) => (
        <div>{inputValue ? "Sem resultados" : "Digite para pesquisar"}</div>
      )}
      loadOptions={loadInstitutions}
      {...props}
      onChange={(option) => {
        props.onChange(option.value);
      }}
    />
  );
};
