import VMasker from 'vanilla-masker';

export function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
}

export function parseMoney(value = 0) {
    return "R$ " + VMasker.toMoney((parseFloat(value) || 0).toFixed(2))
}

export function removeAcento(text) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    return text;
}