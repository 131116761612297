import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import Loading from '../../components/Loading';

import $ from 'jquery';
import { toast } from 'react-toastify';

import '../../Libs/fontawesome/css/all.css';
import { removeAcento } from '../../Util/Functions';

const columns = [
  { title: "Curso", data: null, render: (data, type, row) => { if (type === 'display') { return row.name } else { return removeAcento(row.name) } } },
  { title: "Categoria", data: null, render: (data, type, row) => { if (type === 'display') { return row.category.name } else { return removeAcento(row.category.name) } } },
  { title: "Ativo" },
  { title: "Ações" },
];

const columnDefs = [
  {
    targets: [2],
    data: null,
    render: function(data, type, row) {
      return row.active ? 'Sim' : 'Não'
    }
  },
  {
    targets: -1,
    data: null,
    defaultContent: '<a class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-course"></i></span></a>'
      + '<a class=" action-button"><i class="fas fa-check action-icon toggle-course"></i></span></a>'
      + '<a class=" action-button"><i class="far fa-trash-alt action-icon delete-course"></i></span></a>'
  }
];


export default class Courses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courses: [],
      loading: true,
      modalCreateOpen: false
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (update) => {
    try{
      let response = await Api.get('/course');
      this.setState({ courses: response.data.courses, loading: false });
      if (update === true) {
        this.table.reloadTableData(response.data.courses);
      }
    }catch(error){
      this.setState({ loading: false });
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
   
  }

  handleSuccess = (message) => {
    this.toggleModal();
    this.getData(true);
    toast.success(message);
  }

  handleError = (message) => {
    toast.error(message);
  }

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents('tr');
    if (current_row.hasClass('child')) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes('delete-course')) {
      if (window.confirm(`Deseja excluir o curso ${data.name}?`)) this.deleteCourse(data.id);
    }
    else if (e.target.className.includes('toggle-course')) {
      let stringMessage = `Deseja habilitar o curso ${data.name}?`;
      if (data.active === true) stringMessage = `Deseja desabilitar o curso ${data.name}?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.toggleCourse(data.id);
    }
    else if (e.target.className.includes('edit-course')) {
      this.openCourseEditModal(data.id);
    }
  }

  openCourseEditModal = (courseId) => {
    this.setState({ courseId }, this.toggleModal)
  }

  toggleCourse = async (courseId) => {
    try {
      await Api.put('/course/toggle', { course_id: courseId });
      toast.success('Curso atualizado.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  deleteCourse = async (courseId) => {
    try {
      await Api.delete('/course', { data: { course_id: courseId } });
      toast.success('Curso excluído.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ courseId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
        {this.state.courses.length === 0 ? <div className="alert alert-info">Nenhum curso cadastrado.</div> : <DataTable
          data={this.state.courses}
          columns={columns}
          options={{
            buttons: [],
            responsive: true,
            columnDefs: columnDefs,
            destroy: true
          }}
          handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
          ref={ref => this.table = ref}
        />}
       
        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          courseId={this.state.courseId ? this.state.courseId : null}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={message => { this.handleSuccess(message) }}
          onError={message => { this.handleError(message) }}
        />
      </div>
    )
  }
}

