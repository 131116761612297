import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';

import './index.css';

import Api from '../../../Services/Cloud';
import { toast } from 'react-toastify';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

ReactModal.setAppElement('#root');

export default class Modal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            newScholarshipId: '',
            requesting: false
        }
    }

    componentDidMount() {
        this.startModal();
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requesting: true });
        let data = {
            orderId: this.props.orderId,
            newScholarshipId: this.state.newScholarshipId
        }
        try {
            await Api.put('/order/change', data);
            this.setState({ requesting: false });
            toast.success('Dados atualizados');
            this.clear();
            this.props.onSuccess();
        } catch (error) {
            this.setState({ requesting: false });
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    clear = () => {
        this.setState({
            newScholarshipId: '',
            requesting: false
        })
    }

    startModal = async () => {
        if (this.props.orderId) {
            try {
                let response = await Api.get('/order/id/' + this.props.orderId);
                let order = response.data.order;
                console.log(order)

            } catch (error) {
                console.log(error)
            }
        }
    }

    handleClose = () => {
        this.clear();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Mudança de bolsa</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0 d-f">

                    <form id="scholarship-form" onSubmit={e => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            <label>Nova bolsa: </label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.newScholarshipId}
                                onChange={e => { this.setState({ newScholarshipId: e.target.value }) }}
                                required
                            />
                        </div>
                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button disabled={this.state.requesting} type="submit" className="btn btn-primary">Salvar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}