import React, { Component } from 'react'
import Loading from '../../components/Loading';
import { Link, Redirect  } from 'react-router-dom';
import moment from 'moment';
import { SelectInstitution } from '../../components/SelectInstitution';

export default class Impact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedInstitution: "all",
    }
  }

  handleSubmit = (e) => {
    if (this.state.selectedInstitution === 'all') {
      e.preventDefault();
      alert('Selecione uma instituição.');
    };
  }

  render() {
    if (this.state.submit) return <Redirect  to={{ pathname: '/impact_report', search: `?institution=${this.state.selectedInstitution}` }} />
    return (
      <div className="mt-2 w-100">
        <form className="row p-1" onSubmit={this.handleSubmit}>
          <div className="form-group col-md-4">
            <SelectInstitution onChange={id => { this.setState({ selectedInstitution: id }) }} />
          </div>
          <div className="form-group col-md-2">
            <Link
              to={{
                pathname: '/impact_report',
                search: `?institution=${this.state.selectedInstitution}&course=${this.state.selectedCourse}&start_period=${moment(this.state.start_period).format('YYYY-MM-DD')}&end_period=${moment(this.state.end_period).format('YYYY-MM-DD')}`,
              }}
              target="_blank"
              onClick={this.handleSubmit}
              className="btn btn-primary btn-block">
              <span>BUSCAR</span>
            </Link>
          </div>
        </form>
      </div>
    )
  }
}
