import React, { Component } from 'react';
import Api from '../../Services/Cloud';
import { Redirect  } from "react-router-dom";

import './index.css';

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            reset_password: false,
            loading: false,
            errorMessage: '',
            logged: false
        }
    }

    setErrorMessage = (message) => {
        this.setState({ errorMessage: message });
    }

    componentDidMount() {
       
    }

    handleLogin = async () => {
        this.setState({ loading: true, errorMessage: '' });
        try {
            let response = await Api.post('/auth', { username: this.state.username, password: this.state.password, access_type: 'backoffice' });
            response = response.data;
            if (response.ok === true) {
                localStorage.setItem('logged', true);
                localStorage.setItem('userName', response.user);
                localStorage.setItem('userType', response.type);
                localStorage.setItem('userToken', response.token);
                window.location.replace('/app/institutions');
            }
        } catch (error) {
            console.log('login error')
            this.setState({ loading: false });
            if (error.response && error.response.data) {
                switch (error.response.data.message) {
                    case 'wrong-password':
                        this.setErrorMessage('Senha incorreta')
                        break;
                    case 'user-not-found':
                        this.setErrorMessage('Usuário não encontrado')
                        break;
                    default:
                        this.setErrorMessage(error.response.data.message)
                        break;
                }
            } else {
                console.log('Network error')
                this.setErrorMessage('Erro de conexão')
            }
        }
    }

    render() {
        if (this.state.logged) return (<Redirect  to="/app/institutions" />)
        return (
            <div className="root d-flex align-items-center justify-content-center">
                <div className="simple-login-container p-4">
                    <h2>Painel do parceiro</h2>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <input type="text" className="form-control" placeholder="Email" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <input type="password" placeholder="Senha" className="form-control" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <button className="btn btn-primary btn-block" disabled={this.state.loading} onClick={this.handleLogin}>Enviar</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <span className="error-message">{this.state.errorMessage}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-link btn-block" disabled={this.state.loading}>Esqueci minha senha</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
