import React, { Component } from "react";
import ReactModal from "react-modal";

import PropTypes from "prop-types";

import "react-quill/dist/quill.snow.css";
import "./index.css";

import Api from "../../../Services/Cloud";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.50)",
    zIndex: 2,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
  },
};

ReactModal.setAppElement("#root");

export default class Modal extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      name: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData(document.getElementById("shift-form"));
    var shiftData = {};
    formData.forEach((value, key) => {
      shiftData[key] = value;
    });
    try {
      if (this.props.shiftId) {
        shiftData.shift_id = this.props.shiftId;
        await Api.put("/shift", shiftData);
        this.props.onSuccess("Cadastro atualizado");
      } else {
        await Api.post("/shift", shiftData);
        this.props.onSuccess("Cadastro realizado");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.userMessage)
          return this.props.onError(error.response.data.userMessage);
        return this.props.onError(error.response.data.message);
      }
      this.props.onError(error.message);
    }
  };

  startModal = async () => {
    if (this.props.shiftId) {
      try {
        let response = await Api.get("/shift" + this.props.shiftId);
        let shift = response.data.shift;
        this.setState({
          name: shift.name,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleClose = () => {
    this.clearForm();
    if (this.props.onRequestClose) this.props.onRequestClose();
  };

  clearForm = () => {
    this.setState({
      name: "",
      duration: "",
    });
  };

  render() {
    return (
      <ReactModal
        {...this.props}
        style={customStyles}
        onAfterOpen={this.startModal}
        onRequestClose={this.handleClose}
        onAfterClose={this.clearForm}
      >
        <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
          <h5>Cadastrar turno</h5>
          <i
            className="fas fa-times close-icon p-2"
            onClick={this.props.onRequestClose}
          ></i>
        </div>

        <div className="custom-modal-body px-3 pt-2 pb-0">
          <form
            id="shift-form"
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            <div className="form-group">
              <label>Nome</label>
              <input
                type="text"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
                className="form-control"
                name="name"
                placeholder="Ex: Manhã"
                required
              />
            </div>
            <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                onClick={this.handleClose}
              >
                Cancelar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </ReactModal>
    );
  }
}
