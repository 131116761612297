import React, { Component } from 'react';
import ReactModal from 'react-modal';

import ReactQuill from 'react-quill';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';
import axios from 'axios';
import moment from 'moment';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
];

ReactModal.setAppElement('#root');

export default class Modal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            sellers: [],
            name: "",
            description: "",
            cnpj: "",
            seller: "",
            phone: "",
            phone_2: "",
            phone_3: "",
            owner_name: "",
            owner_phone: "",
            owner_secondary_phone: "",
            owner_birthdate: "",
            operator_name: "",
            operator_phone: "",
            operator_birthdate: "",
            operator_2_name: "",
            operator_2_phone: "",
            operator_2_birthdate: "",
            postal_code: "",
            city: "",
            street: "",
            number: "",
            district: "",
            email: "",
            email_2: "",
            state: "",
            observations: "",
            students_count: 0,
            image: null,
            addressLoading: false,
            fake: false
        }
    }


    componentDidMount() {
        this.getSellers();
    }

    getSellers = async () => {
        try {
            let sellersResponse = await Api.get('/seller');
            console.log(sellersResponse.data.sellers)
            this.setState({ sellers: sellersResponse.data.sellers });
        } catch (error) {          
            console.log(error);
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('institution-form'));
        formData.set("description", this.state.description);
        formData.set("observations", this.state.observations);
        formData.set("fake", this.state.fake);
        try {
            if (this.props.institutionId) {
                formData.set('institution_id', this.props.institutionId)
                await Api.put('/institution', formData);
                this.props.onSuccess("Atualizado atualizado");
            } else {
                await Api.post('/institution', formData);
                this.props.onSuccess("Cadastro realizado");
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        }
    }

    startModal = async () => {
        if (this.props.institutionId) {
            try {
                let response = await Api.get('/institution/id/' + this.props.institutionId);
                let institution = response.data.institution;
                this.setState({
                    id: institution.id,
                    name: institution.name,
                    description: institution.description,
                    cnpj: institution.cnpj,
                    seller: institution?.seller?.id,
                    phone: institution.phone,
                    phone_2: institution.phone_2,
                    phone_3: institution.phone_3,
                    owner_name: institution.owner_name,
                    owner_phone: institution.owner_phone,
                    owner_birthdate: institution.owner_birthdate ? moment(institution.owner_birthdate).format('DD/MM/YYYY') : '',
                    owner_secondary_phone: institution.owner_secondary_phone ? institution.owner_secondary_phone : "",
                    postal_code: institution.postal_code,
                    city: institution.city,
                    email: institution.email,
                    email_2: institution.email_2,
                    operator_name: institution.operator_name,
                    operator_phone: institution.operator_phone,
                    operator_birthdate: institution.operator_birthdate ? moment(institution.operator_birthdate).format('DD/MM/YYYY') : '',
                    operator_2_name: institution.operator_2_name,
                    operator_2_phone: institution.operator_2_phone,
                    operator_2_birthdate: institution.operator_2_birthdate ? moment(institution.operator_2_birthdate).format('DD/MM/YYYY') : '',
                    street: institution.street,
                    number: institution.number,
                    district: institution.district,
                    state: institution.state,
                    students_count: institution.students_count,
                    observations: institution.observations,
                    fake: institution.fake
                });

            } catch (error) {
                console.log(error)
            }
        }
    }

    handleClose = () => {
        this.clearForm();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    clearForm = () => {
        this.setState({
            name: "",
            description: "",
            cnpj: "",
            seller: "",
            phone: "",
            phone_2: "",
            phone_3: "",
            owner_name: "",
            owner_phone: "",
            owner_secondary_phone: "",
            owner_birthdate: "",
            operator_name: "",
            operator_phone: "",
            operator_birthdate: "",
            operator_2_name: "",
            operator_2_phone: "",
            operator_2_birthdate: "",
            postal_code: "",
            city: "",
            email: "",
            email_2: "",
            street: "",
            number: "",
            district: "",
            state: "",
            fake: false,
            observations: "",
            students_count: 0,
            image: null,
        })
    }

    handleCepChange = async () => {
        this.setState({ addressLoading: true, cepError: false });
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${this.state.postal_code}/json/`);
            this.setState({ addressLoading: false });
            if (response.data.erro === true) {
                this.setState({
                    cepError: true,
                    street: "",
                    district: "",
                    city: ""
                });
            } else {
                this.setState({
                    street: response.data.logradouro,
                    district: response.data.bairro,
                    city: response.data.localidade,
                    state: response.data.uf
                });
            }
        } catch (error) {
            this.setState({ cepError: true, addressLoading: false })
            console.log(error.response);
        }
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Cadastrar nova instituição</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0">
                    <form id="institution-form" onSubmit={e => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            <label>Nome</label>
                            <input
                                type="text"
                                value={this.state.name}
                                onChange={e => { this.setState({ name: e.target.value }) }}
                                className="form-control"
                                name="name"
                                placeholder="Ex: UFRPE - Universidade Federal Rural de Pernambuco"
                                required
                            />
                        </div>
                        <div className="form-group editor-container">
                            <label>Descrição</label>
                            <ReactQuill
                                value={this.state.description}
                                onChange={value => { this.setState({ description: value }) }}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                        <div className="form-group">
                            <label>CNPJ</label>
                            <MaskedInput
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                                className="form-control input-cnpj"
                                name="cnpj"
                                placeholder="12.345.678/9012-34"
                                defaultValue={this.state.cnpj ? this.state.cnpj : ""}
                                required
                            />
                        </div>

                        <div className="row no-gutters">
                            <label className="col-12">Dados do responsável pela instituição</label>
                            <div className="col-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={this.state.owner_name}
                                        onChange={e => { this.setState({ owner_name: e.target.value }) }}
                                        className="form-control"
                                        name="owner_name"
                                        placeholder="Nome do responsável"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control ml-1"
                                    name="owner_birthdate"
                                    guide={false}
                                    placeholder="Data de nascimento"
                                    defaultValue={this.state.owner_birthdate ? this.state.owner_birthdate : ""}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters form-group">
                            <label className="col-12">Telefones do responsável</label>
                            <div className="col-6">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control mr-1"
                                    name="owner_phone"
                                    guide={false}
                                    placeholder="Telefone 1 (obrigatório)"
                                    defaultValue={this.state.owner_phone ? this.state.owner_phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control ml-1"
                                    name="owner_secondary_phone"
                                    guide={false}
                                    placeholder="Telefone 2 (opcional)"
                                    defaultValue={this.state.owner_secondary_phone ? this.state.owner_secondary_phone : ""}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Vendedor</label>
                            <select
                                value={this.state.seller}
                                onChange={e => { this.setState({ seller: e.target.value }) }}
                                className="form-control"
                                name="seller"
                                defaultValue=""
                                required
                            >
                                <option value="" disabled>Selecione um vendedor</option>
                                {this.state.sellers.map(seller => (
                                    <option key={seller.id} value={seller.id}>{seller.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="row no-gutters">
                            <label className="col-12">Emails</label>
                            <div className="col-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={this.state.email}
                                        onChange={e => { this.setState({ email: e.target.value }) }}
                                        className="form-control"
                                        name="email"
                                        placeholder="Email da instituição (obrigatório)"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={this.state.email_2}
                                        onChange={e => { this.setState({ email_2: e.target.value }) }}
                                        className="form-control"
                                        name="email_2"
                                        placeholder="Email 2 (opcional)"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <label className="col-12">Telefones da instituição</label>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control input-phone"
                                        name="phone"
                                        guide={false}
                                        placeholder="Telefone (obrigatório)"
                                        defaultValue={this.state.phone ? this.state.phone : ""}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control input-phone"
                                        name="phone_2"
                                        guide={false}
                                        placeholder="Telefone (opcional)"
                                        defaultValue={this.state.phone_2 ? this.state.phone_2 : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control input-phone"
                                        name="phone_3"
                                        guide={false}
                                        placeholder="Telefone (opcional)"
                                        defaultValue={this.state.phone_3 ? this.state.phone_3 : ""}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="row no-gutters">
                            <label className="col-12">Respons. Operac. 01</label>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control input-phone"
                                        name="operator_phone"
                                        guide={false}
                                        placeholder="Telefone (obrigatório)"
                                        defaultValue={this.state.operator_phone ? this.state.operator_phone : ""}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={this.state.operator_name}
                                        onChange={e => { this.setState({ operator_name: e.target.value }) }}
                                        className="form-control"
                                        name="operator_name"
                                        placeholder="Nome"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control ml-1"
                                        name="operator_birthdate"
                                        guide={false}
                                        placeholder="Data de nascimento"
                                        defaultValue={this.state.operator_birthdate ? this.state.operator_birthdate : ""}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <label className="col-12">Respons. Operac. 02</label>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control input-phone"
                                        name="operator_2_phone"
                                        guide={false}
                                        placeholder="Telefone (obrigatório)"
                                        defaultValue={this.state.operator_2_phone ? this.state.operator_2_phone : ""}
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={this.state.operator_2_name}
                                        onChange={e => { this.setState({ operator_2_name: e.target.value }) }}
                                        className="form-control"
                                        name="operator_2_name"
                                        placeholder="Nome"
                                    />
                                </div>
                            </div>
                            <div className="col-4 px-1">
                                <div className="form-group">
                                    <MaskedInput
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control ml-1"
                                        name="operator_2_birthdate"
                                        guide={false}
                                        placeholder="Data de nascimento"
                                        defaultValue={this.state.operator_2_birthdate ? this.state.operator_2_birthdate : ""}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <label className="col-12">CEP</label>
                            <div className="col-6">
                                <input
                                    value={this.state.postal_code}
                                    onChange={e => { this.setState({ postal_code: e.target.value }) }}
                                    type="text"
                                    className="form-control"
                                    name="postal_code"
                                    placeholder="CEP"
                                    onBlur={this.handleCepChange}
                                    required
                                />
                            </div>
                            {this.state.addressLoading
                                ?
                                (
                                    <div className="col-1 d-flex align-items-center justify-content-center">
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </div>
                                )
                                :
                                null
                            }
                            {this.state.cepError
                                ?
                                (
                                    <div className="cep-error-message mb-2 text-danger">
                                        Erro ao buscar endereço
                                    </div>
                                )
                                :
                                null
                            }

                        </div>
                        <div className="row no-gutters">
                            <label className="col-12">Endereço</label>
                            <div className="col-9">
                                <input
                                    value={this.state.street}
                                    onChange={e => { this.setState({ street: e.target.value }) }}
                                    type="text"
                                    className="form-control mr-1"
                                    name="street"
                                    placeholder="Logradouro"
                                    required />
                            </div>
                            <div className="col-3">
                                <input
                                    value={this.state.number}
                                    onChange={e => { this.setState({ number: e.target.value }) }}
                                    type="text"
                                    className="form-control ml-1"
                                    name="number"
                                    placeholder="Número"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Cidade</label>
                            <input
                                value={this.state.city}
                                onChange={e => { this.setState({ city: e.target.value }) }}
                                type="text"
                                className="form-control"
                                placeholder="Ex: Caruaru"
                                name="city"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Bairro</label>
                            <input
                                type="text"
                                value={this.state.district}
                                onChange={e => { this.setState({ district: e.target.value }) }}
                                className="form-control"
                                placeholder="Bairro"
                                name="district"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Estado</label>
                            <select
                                className="form-control"
                                value={this.state.state}
                                onChange={e => { this.setState({ state: e.target.value }) }}
                                name="state"
                                required
                            >
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Número de alunos</label>
                            <input
                                value={this.state.students_count}
                                onChange={e => { this.setState({ students_count: e.target.value }) }}
                                type="text"
                                className="form-control"
                                name="students_count"
                                placeholder="Digite a quantidade de alunos na instituição"
                                aria-describedby="inputInstitutionStudentsCountHelp"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Imagem</label>
                            <input
                                type="file"
                                name="image"
                                className="form-control-file"
                            />
                        </div>
                        <div className="row no-gutters">
                            <div className="form-group w-100">
                                <label htmlFor="fake">Observações</label>
                                <TextareaAutosize
                                    value={this.state.observations}
                                    onChange={e => { this.setState({ observations: e.target.value }) }}
                                    className="form-control w-100"
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <input
                                type="checkbox"
                                className="mr-2 mt-1"
                                checked={this.state.fake}
                                name="fake"
                                id="fake"
                                onChange={e => { this.setState({ fake: e.target.checked }) }}
                            />
                            <label htmlFor="fake">Fake</label>
                        </div>

                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button type="submit" className="btn btn-primary">Salvar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}