import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';
import { SelectInstitution } from '../../../components/SelectInstitution';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
    zIndex: 2
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    minWidth: '40%'
  }
};

ReactModal.setAppElement('#root');

export default class Modal extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func
  }
  constructor(props) {
    super(props);

    this.state = {
      institutions: [],
      name: "igor parente",
      email: "figorpdf@gmail.com",
      password: "123456",
      repassword: "123456",
      type: "admin",
      phone: "(81)99351-1962",
      self_responsible: true,
      cpf: "118.336.714-73",
      rg: "9448006",
      birthdate: "1996-09-27",
      institution_id: 1,
      familyIncome: 1250.34,
      address: {
        street: "Rua R50",
        city: "Caruaru",
        state: "PE",
        number: 323,
        postalCode: "55021-315",
        district: "Cidade Jardim"
      }
    }
  }

  componentDidMount() {
    this.getInstitutions();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData(document.getElementById('course-category-form'));
    var userData = {};
    formData.forEach((value, key) => { userData[key] = value });

    if (userData.password !== userData.repassword) return toast.error("As senhas não conferem");

    userData.phone = '(81)99999-9999'
    userData.self_responsible = true;
    userData.cpf = '086.692.550-30';
    userData.rg = '9999999';
    userData.rg_emissor = 'sds';
    userData.family_income = 1250.34;
    userData.birthdate = '1996-04-25';
    userData.street = "Rua do Cedro";
    userData.city = "Caruaru";
    userData.state = "PE";
    userData.number = 100;
    userData.postal_code = "55020-605";
    userData.district = "Cedro";
    try {
      if (this.props.userId) {
        userData.user_id = this.props.userId;
        await Api.put('/user', userData);
        this.props.onSuccess("Cadastro atualizado");
      } else {
        await Api.post('/user/register', userData);
        this.props.onSuccess("Cadastro realizado");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
        return this.props.onError(error.response.data.message)
      }
      this.props.onError(error.message);
    }
  }

  startModal = async () => {
    if (this.props.userId) {
      try {
        let response = await Api.get('/user/id/' + this.props.userId);
        let user = response.data.user;
        this.setState({
          name: user.name,
          email: user.email,
          type: user.type,
          institution_id: user.institution_id
        });

      } catch (error) {
        console.log(error)
      }
    }
  }

  handleClose = () => {
    this.clearForm();
    if (this.props.onRequestClose) this.props.onRequestClose();
  }

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      password: "",
      repassword: "",
      type: "",
      institution_id: ""
    })
  }

  getInstitutions = async () => {
    try {
      let response = await Api.get('institution');
      let institutions = response.data.institutions;
      this.setState({ institutions: institutions })
    } catch (error) {
      toast.error("Erro ao buscar instituições");
      console.log(error)
    }
  }

  render() {
    return (
      <ReactModal
        {...this.props}
        style={customStyles}
        onAfterOpen={this.startModal}
        onRequestClose={this.handleClose}
        onAfterClose={this.clearForm}
      >
        <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
          <h5>Cadastrar novo usuário</h5>
          <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
        </div>

        <div className="custom-modal-body px-3 pt-2 pb-0">
          <form id="course-category-form" autoComplete="off" onSubmit={e => { this.handleSubmit(e) }}>
            <div className="row no-gutters">
              <div className="col-6 pr-2">
                <label>Função</label>
                <select
                  className="form-control"
                  name="type"
                  value={this.state.type}
                  onChange={e => { this.setState({ type: e.target.value }) }}
                  required
                >
                  <option disabled value="">Selecionar tipo</option>
                  <option value="admin">Administrador</option>
                  <option value="manager">Parceiro</option>
                </select>
              </div>
              <div className="col-6 pl-2">
                {this.state.type === 'manager'
                  ?
                  (<div>
                    <label>Instituição</label>
                    <SelectInstitution onChange={(id) => { this.setState({ institution_id: id }) }} />
                  </div>)
                  :
                  null
                }
              </div>
            </div>
            <div className="form-group mt-2">
              <label>Nome</label>
              <input
                type="text"
                value={this.state.name}
                onChange={e => { this.setState({ name: e.target.value }) }}
                className="form-control"
                name="name"
                placeholder="Nome do usuário"
                required
              />
            </div>
            <div className="form-group mt-2">
              <label>Email</label>
              <input
                type="text"
                autoComplete="false"
                value={this.state.email}
                onChange={e => { this.setState({ email: e.target.value }) }}
                className="form-control"
                name="email"
                placeholder="Nome do usuário"
                required
              />
            </div>
            {!this.props.userId
              ?
              (
                <div className="row no-gutters">
                  <div className="col-6 pr-2">
                    <label>Senha</label>
                    <input
                      type="password"
                      autoComplete="new-password"
                      value={this.state.password}
                      onChange={e => { this.setState({ password: e.target.value }) }}
                      className="form-control"
                      name="password"
                      placeholder="Senha de acesso"
                      required
                    />
                  </div>
                  <div className="col-6 pl-2">
                    <label>Confirmar senha</label>
                    <input
                      type="password"
                      value={this.state.repassword}
                      onChange={e => { this.setState({ repassword: e.target.value }) }}
                      className="form-control"
                      name="repassword"
                      placeholder="Digite a senha novamente"
                      required
                    />
                  </div>
                </div>
              )
              :
              null
            }


            <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3 mt-3">
              <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
              <button type="submit" className="btn btn-primary">Salvar</button>
            </div>
          </form>
        </div>
      </ReactModal>
    )
  }
}
