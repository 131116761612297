import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import Loading from '../../components/Loading';

import $ from 'jquery';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import '../../Libs/fontawesome/css/all.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import * as rdrLocales from 'react-date-range/src/locale';

const columns = [
  {
    className: 'details-control',
    orderable: false,
    data: null,
    defaultContent: '',
    render: function(data, type, row) { return '<div class="text-center"><i class="fas fa-plus"></i></div>' }
  },
  { title: "Nome", data: "name" },
  { title: "Nº de usuários", data: "UsersCount" },
  { title: "Ativo" },
  { title: "Ações" },
];

const columnDefs = [
  {
    targets: [3],
    data: null,
    render: function(data, type, row) {
      return row.active ? 'Sim' : 'Não'
    }
  },
  {
    targets: -1,
    data: null,
    defaultContent: '<a class=" action-button" data-toggle="modal" data-target="#editPartnerModal"><i class="fas fa-edit action-icon edit-partner"></i></span></a>'
      + '<a class=" action-button"><i class="fas fa-check action-icon toggle-partner"></i></span></a>'
      + '<a class=" action-button"><i class="far fa-trash-alt action-icon delete-partner"></i></span></a>'
  }
];
//


export default class Partners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      partners: [],
      loading: true,
      modalCreateOpen: false,
      showDatePicker: false
    }
  }

  componentDidMount() {
    this.getData();
  }

  format(d) {
    let string = '';
    d.users.forEach(user => {
      string = string + user.name + '<br />'
    });
    return string;
  }

  getData = async (update, dates) => {
    let params = {};
    if (dates) {
      params = { startDate: dates.startDate, endDate: dates.endDate }
    }
    try{
      let response = await Api.get('/partner', { params });
      this.setState({ partners: response.data.partners, loading: false });
      var that = this;
      let table = this.table.getTable();
      if (update === true) {
        this.table.reloadTableData(response.data.partners);
      }
      $('#institutionsTable tbody').on('click', 'td.details-control', function() {
        if (this.className !== 'student-data') {
          var tr = $(this).closest('tr');
          var row = table.row(tr);
  
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown');
          }
          else {
            // Open this row
            row.child(that.format(row.data())).show();
            tr.addClass('shown');
          }
        }
      });
    }catch(error){
      this.setState({ loading: false });
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
 
  
  }

  handleSuccess = (message) => {
    this.toggleModal();
    this.getData(true);
    toast.success(message);
  }

  handleError = (message) => {
    toast.error(message);
  }

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents('tr');
    if (current_row.hasClass('child')) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes('delete-partner')) {
      if (window.confirm(`Deseja excluir o Parceiro ${data.name}?`)) this.deletePartner(data.id);
    }
    else if (e.target.className.includes('toggle-partner')) {
      let stringMessage = `Deseja habilitar o Parceiro ${data.name}?`;
      if (data.active === true) stringMessage = `Deseja desabilitar o Parceiro ${data.name}?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.togglePartner(data.id);
    }
    else if (e.target.className.includes('edit-partner')) {
      this.openPartnerEditModal(data.id);
    }
  }

  openPartnerEditModal = (partnerId) => {
    this.setState({ partnerId }, this.toggleModal)
  }

  togglePartner = async (partnerId) => {
    try {
      await Api.put('/partner/toggle', { partner_id: partnerId });
      toast.success('Parceiro atualizado.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  deletePartner = async (partnerId) => {
    try {
      await Api.delete('/partner', { data: { partner_id: partnerId } });
      toast.success('Parceiro excluído.')
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  }

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ partnerId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
  }

  toggleDatePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker })
  }

  handleSelect = (ranges) => {
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate })
    this.getData(true, { startDate: moment(ranges.selection.startDate).format('MM-DD-YYYY'), endDate: moment(ranges.selection.endDate).format('MM-DD-YYYY') });
  }




  render() {
    if (this.state.loading) return <Loading />;
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: 'selection',
    }
    return (
      <div className="mt-2 w-100">
        <div className="row">
          <div className="col-12">
            <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
            <button className="btn btn-primary mb-4 ml-3" onClick={this.toggleDatePicker}>PERÍODO</button>
          </div>
          {
            this.state.showDatePicker ?
              <div className="col-12">
                <DateRangePicker
                  locale={rdrLocales.pt}
                  ranges={[selectionRange]}
                  onChange={this.handleSelect}
                  staticRanges={[{
                    label: "Hoje",
                    hasCustomRendering: true,
                    range: () => ({
                      startDate: new Date(),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      return (
                        false
                      );
                    },
                  },
                  {
                    label: "Essa semana",
                    hasCustomRendering: true,
                    range: () => ({
                      startDate: moment().startOf('week'),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      return (
                        false
                      );
                    },
                  },
                  {
                    label: "Esse mês",
                    hasCustomRendering: true,
                    range: () => ({
                      startDate: moment().startOf('month'),
                      endDate: new Date(),
                    }),
                    isSelected() {
                      return (
                        false
                      );
                    },
                  },
                  {
                    label: "Mês passado",
                    hasCustomRendering: true,
                    range: () => ({
                      startDate: moment().subtract(1, 'month').startOf('month'),
                      endDate: moment().subtract(1, 'month').endOf('month'),
                    }),
                    isSelected() {
                      return (
                        false
                      );
                    },
                  }
                  ]}
                />
              </div>
              :
              null
          }
        </div>
        <DataTable
          data={this.state.partners}
          columns={columns}
          options={{
            buttons: [],
            responsive: true,
            columnDefs: columnDefs,
            destroy: true
          }}
          handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
          ref={ref => this.table = ref}
        />
        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          partnerId={this.state.partnerId ? this.state.partnerId : null}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={message => { this.handleSuccess(message) }}
          onError={message => { this.handleError(message) }}
        />
      </div>
    )
  }
}

