import React, { Component } from "react";
import Api from "../../Services/Cloud";
import moment from "moment";
import DataTable from "../../components/DataTable";
import { parseMoney } from "../../Util/Functions";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Loading from "../../components/Loading";

import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";
import { SelectInstitution } from "../../components/SelectInstitution";

const columns = [
  { title: "Aluno", data: "order.user.name" },
  { title: "Curso/Série", data: "order.scholarship.course.name" },
  { title: "Instituição", data: "order.scholarship.institution.name" },
  {
    title: "Turno",
    render: function (data, type, row) {
      return row.order.scholarship.shift?.name ?? "N/A";
    },
  },
  {
    title: "Valor da mensalidade",
    data: "order.scholarship.full_price",
    render: function (data, type, row) {
      return type === "display"
        ? parseMoney(row.order.scholarship.full_price)
        : row.order.scholarship.full_price;
    },
  },
  {
    title: "Desconto",
    render: function (data, type, row) {
      return type === "display"
        ? row.order.scholarship.discount + "%"
        : row.order.scholarship.discount;
    },
  },
  {
    title: "Valor com desconto",
    render: function (data, type, row) {
      return type === "display"
        ? parseMoney(row.order.scholarship.final_price)
        : row.order.scholarship.final_price;
    },
  },
  {
    title: "Data de inscricao",
    render: function (data, type, row) {
      return moment(row.order.createdAt).format("DD/MM/YYYY");
    },
  },
  {
    title: "Próxima renovação",
    render: function (data, type, row) {
      return moment(row.order.createdAt).add(6, "month").format("DD/MM/YYYY");
    },
  },
  {
    title: "Valor próx. renovação",
    data: "order.scholarship.full_price",
    render: function (data, type, row) {
      return type === "display"
        ? parseMoney(row.order.scholarship.full_price)
        : row.order.scholarship.full_price;
    },
  },
];

const columnDefs = [{ visible: false, targets: [2] }];

export default class Students extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      institutions: [],
      courses: [],
      payments: [],
      loading: true,
      selectedInstitution: "all",
      selectedCourse: "all",
      start_period: null,
      end_period: null,
      showTable: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  startSelect2 = () => {
    var ctx = this;
    $.fn.select2.defaults.set("language", {
      noResults: function () {
        return "Sem resultados";
      },
      noMatches: function () {
        return "Nenhum resultado encontrado";
      },
      inputTooShort: function (input, min) {
        return "Digite no mínimo 2 caracteres.";
      },
      loadMore: function (pageNumber) {
        return "Buscando...";
      },
      searching: function () {
        return "Buscando...";
      },
    });
    $(".institution-select").select2({
      placeholder: "Instituição",
      theme: "bootstrap4",
    });
    $(".course-select").select2({
      placeholder: "Curso",
      theme: "bootstrap4",
    });
    $(".institution-select").on("select2:select", async function (e) {
      ctx.setState({ selectedInstitution: e.target.value });
    });
    $(".course-select").on("select2:select", async function (e) {
      ctx.setState({ selectedCourse: e.target.value });
    });
  };

  getData = async (update) => {
    let coursesRequest = await Api.get("/course");
    console.log(coursesRequest.data.courses, "courses");
    let courses = coursesRequest.data.courses;
    this.setState({ courses, loading: false }, () => {
      this.startSelect2();
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let dataRequest = await Api.get("/report/general", {
        params: {
          institution: this.state.selectedInstitution,
          course: this.state.selectedCourse,
          start_date: this.state.start_period,
          end_date: this.state.end_period,
        },
      });
      this.setState({ showTable: true, payments: dataRequest.data.payments });
      this.table.reloadTableData(dataRequest.data.payments);
    } catch (error) {
      console.log(error.response);
    }
  };

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <form className="row p-1" onSubmit={this.handleSubmit}>
          <div className="form-group col-md-3">
            <SelectInstitution
              defaultOptions={[{ value: "all", label: "TODAS" }]}
              onChange={(id) => {
                this.setState({ selectedInstitution: id });
              }}
              defaultValue={{ value: "all", label: "TODAS" }}
            />
          </div>
          <div className="form-group col-md-3">
            <select
              className="form-control course-select"
              name="course"
              value={this.state.selectedCourse}
              onChange={(e) => {
                this.setState({ selectedCourse: e.target.value });
              }}
              required
            >
              <option disabled value="">
                Selecionar curso
              </option>
              <option value="all">TODOS</option>
              {this.state.courses.map((course) => {
                return (
                  <option value={course.id} key={course.id}>
                    {course.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-md-2">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={this.state.start_period}
              className="form-control d-flex"
              placeholderText="De"
              onChange={(value) => {
                this.setState({ start_period: value });
              }}
              required
            />
          </div>
          <div className="form-group col-md-2">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={this.state.end_period}
              className="form-control d-flex"
              placeholderText="Até"
              onChange={(value) => {
                this.setState({ end_period: value });
              }}
              required
            />
          </div>
          <div className="form-group col-md-2">
            <button type="submit" className="btn btn-primary btn-block">
              BUSCAR
            </button>
          </div>
        </form>
        {this.state.showTable ? (
          <DataTable
            data={this.state.payments}
            columns={columns}
            report={true}
            options={{
              buttons: [
                {
                  customize: function (doc) {
                    doc.defaultStyle.fontSize = 10;
                    doc.styles.tableFooter.fontSize = 8;
                    doc.defaultStyle.alignment = "center";
                  },
                  extend: "pdfHtml5",
                  orientation: "landscape",
                  exportOptions: {
                    modifier: {
                      page: "all",
                      search: "none",
                    },
                  },
                  footer: true,
                },
              ],
              responsive: false,
              scrollX: true,
              destroy: true,
              columnDefs: columnDefs,
              rowGroup: {
                startRender: null,
                endRender: function (rows, group) {
                  var firstPaymentSum = rows
                    .data()
                    .pluck("order")
                    .pluck("scholarship")
                    .pluck("full_price")
                    .reduce((a, b) => a + b, 0);

                  return $("<tr/>")
                    .append('<td colspan="2"><b>' + group + "</b></td>")
                    .append("<td/>")
                    .append(
                      "<td><b>" + parseMoney(firstPaymentSum) + "</b></td>",
                    )
                    .append('<td colspan="4"></td>')
                    .append(
                      "<td><b>" + parseMoney(firstPaymentSum) + "</b></td>",
                    );
                },
                dataSrc: "order.scholarship.institution.name",
              },
              footerCallback: function (row, data, start, end, display) {
                var api = this.api();

                // Total over all pages
                let firstTotal = api
                  .column(4)
                  .data()
                  .reduce(function (a, b) {
                    return (parseFloat(a) + parseFloat(b)).toFixed(2);
                  }, 0);

                // Total over this page
                let firstPageTotal = api
                  .column(4, { page: "current" })
                  .data()
                  .reduce(function (a, b) {
                    return (parseFloat(a) + parseFloat(b)).toFixed(2);
                  }, 0);
                // Total over all pages
                let lastTotal = api
                  .column(9)
                  .data()
                  .reduce(function (a, b) {
                    return (parseFloat(a) + parseFloat(b)).toFixed(2);
                  }, 0);

                // Total over this page
                let lastPageTotal = api
                  .column(9, { page: "current" })
                  .data()
                  .reduce(function (a, b) {
                    return (parseFloat(a) + parseFloat(b)).toFixed(2);
                  }, 0);

                // Update footer
                $("#institutionsTable").append(
                  $("<tfoot/>").append(
                    $("#institutionsTable thead tr").clone(),
                  ),
                );
                $(api.column(4).footer()).html(
                  '<span class="table-footer-text">' +
                    parseMoney(firstPageTotal) +
                    "(pg.)</br>" +
                    parseMoney(firstTotal) +
                    "(tudo)</span>",
                );
                $(api.column(9).footer()).html(
                  '<span class="table-footer-text">' +
                    parseMoney(lastPageTotal) +
                    "(pg.)</br>" +
                    parseMoney(lastTotal) +
                    "(tudo)</span>",
                );
                $(api.column(0).footer()).html(
                  '<span class="table-footer-text">Total:</span>',
                );
              },
            }}
            ref={(ref) => (this.table = ref)}
          />
        ) : null}
      </div>
    );
  }
}
