import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import Api from "../../Services/Cloud";

export const Dashboard = () => {
  const [metrics, setMetrics] = useState([]);
  const [loadingMetrics, setLoadingMetrics] = useState(true);

  const fetchMetrics = async () => {
    setLoadingMetrics(true);
    try {
      const { data } = await Api.get("/report/metrics");
      setMetrics(data);
    } catch (error) {
      console.log(error, "error fetching metrics");
      toast.error("Erro ao buscar métricas");
    } finally {
      setLoadingMetrics(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  if (loadingMetrics) {
    return <Loading />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-muted">Instituições</h5>
              <h3 className="card-text">
                <strong className="text-lg">{metrics.institutionsCount}</strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-muted">Cursos</h5>
              <h3 className="card-text">
                <strong className="text-lg">{metrics.coursesCount}</strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-muted">Bolsas</h5>
              <h3 className="card-text">
                <strong className="text-lg">{metrics.scholarshipsCount}</strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title text-muted">Estudantes</h5>
              <h3 className="card-text">
                <strong className="text-lg">{metrics.studentsCount}</strong>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
