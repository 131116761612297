import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Students from "./views/Students";
import Institutions from "./views/Institutions";
import Courses from "./views/Courses";
import CourseCategories from "./views/CourseCategories";
import Users from "./views/Users";
import Scholarships from "./views/Scholarships";
import Unauthorized from "./views/Unauthorized";
import NotFound from "./views/NotFound";
import FAQ from "./views/Faq";
import Reports from "./views/Reports";
import Impact from "./views/Impact";
import Partners from "./views/Partners";
import { Dashboard } from "./views/Dashboard";
import Sellers from "./views/Sellers";
import Shifts from "./views/Shifts";

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("userType") === "admin" ? (
        <Component {...props} />
      ) : (
        <Redirect to="/unauthorized" />
      )
    }
  />
);

const ManagerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("userType") === "manager" ||
      localStorage.getItem("userType") === "admin" ? (
        <Component {...props} />
      ) : (
        <Redirect to="/unauthorized" />
      )
    }
  />
);

export default function Routes() {
  return (
    <Switch>
    <AdminRoute exact path="/app/institutions" component={Institutions} />
    <AdminRoute exact path="/app/courses" component={Courses} />
    <AdminRoute
      exact
      path="/app/course_categories"
      component={CourseCategories}
    />
    <ManagerRoute exact path="/app/scholarships" component={Scholarships} />
    <AdminRoute exact path="/app/impact" component={Impact} />
    <AdminRoute exact path="/app/faq" component={FAQ} />
    <AdminRoute exact path="/app/users" component={Users} />
    <AdminRoute exact path="/app/shifts" component={Shifts} />
    <AdminRoute exact path="/app/partners" component={Partners} />
    <AdminRoute exact path="/app/sellers" component={Sellers} />
    <AdminRoute exact path="/app/dashboard" component={Dashboard} />
    <ManagerRoute exact path="/app/reports" component={Reports} />
    <ManagerRoute exact path="/app/students" component={Students} />
    <Route exact path="/app/unauthorized" component={Unauthorized} />
    <Route component={NotFound} />
  </Switch>
  );
}