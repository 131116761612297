import React, { Component } from "react";

import Api from "../../Services/Cloud";
import DataTable from "../../components/DataTable";
import Modal from "./Modal";
import { parseMoney, removeAcento } from "../../Util/Functions";
import Loading from "../../components/Loading";

import $ from "jquery";
import { toast } from "react-toastify";

import "../../Libs/fontawesome/css/all.css";

const columns = [
  {
    title: "Curso",
    data: null,
    render: (data, type, row) => {
      if (type === "display") {
        return row.course_name;
      } else {
        return removeAcento(row.course_name);
      }
    },
  },
  {
    title: "Instituição",
    data: null,
    render: (data, type, row) => {
      if (type === "display") {
        return (
          row.institution_name.toUpperCase() +
          " - " +
          row.institution_city.toUpperCase() +
          " - " +
          row.institution_district.toUpperCase()
        );
      } else {
        return (
          removeAcento(row.institution_name.toUpperCase()) +
          " - " +
          removeAcento(row.institution_city.toUpperCase()) +
          " - " +
          removeAcento(row.institution_district.toUpperCase())
        );
      }
    },
  },
  {
    title: "Turno",
    data: null,
    render: (data, type, row) => {
      return (row.shift_name ?? "Não definido").toUpperCase();
    },
  },
  {
    title: "Tipo",
    data: null,
    render: (data, type, row) => {
      return row.type.toUpperCase();
    },
  },
  {
    title: "Preço integral",
    data: null,
    render: (data, type, row) => {
      return parseMoney(row.full_price);
    },
  },
  {
    title: "Desconto",
    data: null,
    render: (data, type, row) => {
      return row.discount + "%";
    },
  },
  {
    title: "Preço final",
    data: null,
    render: (data, type, row) => {
      return parseMoney(row.final_price);
    },
  },
  {
    title: "Duração do período",
    data: null,
    render: (data, type, row) => {
      return row.renovation_days + " dias";
    },
  },
  {
    title: "Bolsas Ofertadas/Vendidas",
    data: null,
    render: (data, type, row) => {
      return row.quantity_offered + "/" + row.quantity_sold;
    },
  },
  { title: "Ativo" },
  { title: "Expirou" },
  { title: "Ações" },
];

const columnDefs = [
  {
    targets: [-3],
    data: null,
    render: function (data, type, row) {
      return row.active ? "Sim" : "Não";
    },
  },
  {
    targets: [-2],
    data: null,
    render: function (data, type, row) {
      return row.expired ? "Sim" : "Não";
    },
  },
  {
    targets: -1,
    data: null,
    defaultContent:
      '<a  class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-scholarship"></i></span></a>' +
      '<a  class=" action-button"><i class="fas fa-check action-icon toggle-scholarship"></i></span></a>' +
      '<a  class=" action-button"><i class="far fa-trash-alt action-icon delete-scholarship"></i></span></a>',
  },
];

export default class Scholarships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scholarships: [],
      loading: true,
      modalCreateOpen: false,
      showExpired: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (update) => {
    try {
      let response = await Api.get('/scholarship/list/backoffice', { params: { showExpired: this.state.showExpired } });
      if (response.data.scholarships){
        this.setState({ scholarships: response.data.scholarships, loading: false });
        if (update === true) {
          this.table.reloadTableData(response.data.scholarships);
        }
      }else{
        this.setState({ scholarships: [], loading: false });
      }
    }catch(error){
      this.setState({ loading: false });
      if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
   
    
   
  }

  handleSuccess = (message) => {
    this.toggleModal();
    this.getData(true);
    toast.success(message);
  };

  handleError = (message) => {
    toast.error(message);
  };

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents("tr");
    if (current_row.hasClass("child")) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes("delete-scholarship")) {
      if (window.confirm(`Deseja excluir a bolsa?`))
        this.deleteScholarship(data.id);
    } else if (e.target.className.includes("toggle-scholarship")) {
      let stringMessage = `Deseja habilitar a bolsa?`;
      if (data.active === true) stringMessage = `Deseja desabilitar a bolsa?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.toggleScholarship(data.id);
    } else if (e.target.className.includes("edit-scholarship")) {
      this.openScholarshipModal(data.id);
    }
  };

  openScholarshipModal = (scholarshipId) => {
    this.setState({ scholarshipId }, this.toggleModal);
  };

  toggleScholarship = async (scholarshipId) => {
    try {
      await Api.put("/scholarship/toggle", { scholarship_id: scholarshipId });
      toast.success("Bolsa atualizada.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  deleteScholarship = async (scholarshipId) => {
    try {
      await Api.delete("/scholarship", {
        data: { scholarship_id: scholarshipId },
      });
      toast.success("Bolsa excluída.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ scholarshipId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen });
  };

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <button className="btn btn-primary mb-4" onClick={this.toggleModal}>
          CADASTRAR
        </button>
        <input
          type="checkbox"
          className="ml-4"
          onChange={(e) => {
            this.setState({ showExpired: e.target.checked }, () => {
              this.getData(true);
            });
          }}
        />
        <span>Mostrar bolsas que expiraram</span>
        <DataTable
          data={this.state.scholarships}
          columns={columns}
          options={{
            buttons: [],
            responsive: true,
            columnDefs: columnDefs,
            destroy: true,
          }}
          handleActionClick={(e, dataTable) => {
            this.handleTableClick(e, dataTable);
          }}
          ref={(ref) => (this.table = ref)}
        />
        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          scholarshipId={
            this.state.scholarshipId ? this.state.scholarshipId : null
          }
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={(message) => {
            this.handleSuccess(message);
          }}
          onError={(message) => {
            this.handleError(message);
          }}
        />
      </div>
    );
  }
}
