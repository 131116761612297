import React, { useEffect, useState } from "react";
import Api from "../../Services/Cloud";
import { toast } from "react-toastify";
import Select from "react-select";

export const SelectShift = (props) => {
  const [shifts, setShifts] = useState([]);

  const loadShifts = async () => {
    try {
      const { data } = await Api.get(`/shift`);
      setShifts(
        data.shifts
          .filter((s) => s.active === true)
          .map((shift) => ({
            value: shift.id,
            label: `${shift.name}`,
          })),
      );
    } catch (error) {
      console.log(error, "erro ao buscar turnos");
      toast.error(`Erro ao buscar turnos`);
    }
  };

  useEffect(() => {
    loadShifts();
  }, []);

  return (
    <Select
      placeholder={"Busque um turno"}
      noOptionsMessage={(inputValue) => (
        <div>{inputValue ? "Sem resultados" : "Digite para pesquisar"}</div>
      )}
      options={shifts}
      {...props}
      onChange={(option) => {
        props.onChange(option.value);
      }}
    />
  );
};
