import React, { Component } from 'react'
import { BrowserRouter as Router, Redirect } from "react-router-dom";

import Sidebar from './components/Sidebar';
import Routes from './Routes';

import { ToastContainer } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

import './global.css';

export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logout: false,
        }
    }

    logout = () => {
        localStorage.clear();
        this.setState({ logout: true });
    }

    render() {
        if (this.state.loading) {
            return <div>Verificando</div>
        }
        if (this.state.logout) {
            return <Redirect to="/login" />
        }
        return (
            <Router>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">

                        <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom d-flex justify-content-between">
                            <button className="btn btn-primary" onClick={e => document.getElementById('wrapper').classList.toggle('toggled')}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                            <button className="btn btn-link" onClick={this.logout}>
                                Sair
                        </button>
                        </nav>
                        <div className="container-fluid">
                            <Routes />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </Router >
        )
    }
}