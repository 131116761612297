import React, { Component } from "react";

import Api from "../../Services/Cloud";
import DataTable from "../../components/DataTable";
import Modal from "./Modal";
import Loading from "../../components/Loading";
import ObservationModal from "./ObservationModal";

import $ from "jquery";
import { toast } from "react-toastify";
import moment from "moment";

import "../../Libs/fontawesome/css/all.css";
import { removeAcento } from "../../Util/Functions";

const columns = [
  {
    title: "Instituição",
    data: null,
    render: (data, type, row) => {
      if (type === "display") {
        return row.name + " - " + row.city + " - " + row.district;
      } else {
        return (
          removeAcento(row.name) +
          " - " +
          removeAcento(row.city) +
          " - " +
          removeAcento(row.district)
        );
      }
    },
  },

  {
    title: "Responsável",
    data: null,
    render: (data, type, row) => {
      if (type === "display") {
        return row.owner_name;
      } else {
        return removeAcento(row.owner_name);
      }
    },
  },
  { title: "Fone responsável", data: "owner_phone" },
  { title: "Email", data: "email" },
  { title: "Ativo" },
  { title: "Bolsas ofertadas/vendidas" },
  { title: "Ranking" },
  { title: "Data de criação" },
  {
    title: "Vendedor",
    data: null,
    render: (data, type, row) => {
      if (row.seller === null) return "";
      if (type === "display") {
        return row.seller?.name ?? "";
      } else {
        return removeAcento(row.seller?.name);
      }
    },
  },
  { title: "Ações" },
  { title: "Observações", data: "observations" },
];

const columnDefs = [
  {
    targets: [0],
    render: function (data, type, row) {
      if (type === "display") {
        return row["name"] + " - " + row["city"];
      } else {
        return data;
      }
    },
  },
  {
    targets: [2],
    data: null,
    render: function (data, type, row) {
      if (row.owner_secondary_phone)
        return row.owner_phone + " / " + row.owner_secondary_phone;
      else return row.owner_phone;
    },
  },
  {
    targets: [4],
    data: null,
    render: function (data, type, row) {
      return row.active ? "Sim" : "Não";
    },
  },
  {
    targets: [5],
    data: null,
    render: function (data, type, row) {
      return row.offered_scholarships + "/" + row.scholarships_sold;
    },
  },
  {
    targets: [6],
    data: null,
    render: function (data, type, row) {
      return "A definir";
    },
  },
  {
    //Valor da ultima renovação
    targets: [7],
    data: null,
    render: function (data, type, row) {
      return moment(row.createdAt).format("DD/MM/YYYY");
    },
  },
  {
    targets: -2,
    data: null,
    defaultContent:
      '<a class=" action-button" data-toggle="modal" data-target="#editInstitutionModal"><i class="fas fa-edit action-icon edit-institution"></i></span></a>' +
      '<a class=" action-button"><i class="fas fa-check action-icon toggle-institution"></i></span></a>' +
      '<a class=" action-button"><i class="far fa-trash-alt action-icon delete-institution"></i></span></a>' +
      '<a  class=" action-button" data-toggle="modal"><i class="fas fa-paperclip action-icon edit-observations"></i></span></a>',
  },
];

export default class Institutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutions: [],
      loading: true,
      modalCreateOpen: false,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.getData();
    console.log(this.state.institutions);
  }

  getData = async (update) => {
    try {
      let response = await Api.get("/institution/");
      this.setState({
        institutions: response.data.institutions,
        loading: false,
      });
      if (update === true) {
        this.table.reloadTableData(response.data.institutions);
      }
    } catch (error) {
      this.setState({ loading: false });
      if (update === true) {
        this.table.reloadTableData(this.state.institutions);
      }
      toast.error(error.message);
    }
  };

  handleSuccess = (message) => {
    this.setState({
      modalCreateOpen: false,
      showObservationsModal: false,
      openInstitutionEditModal: false,
    });
    this.getData(true);
    toast.success(message);
  };

  handleError = (message) => {
    toast.error(message);
  };

  toggleObservationsModal = (institutionId) => {
    if (this.state.showObservationsModal) {
      this.setState({ institutionId: null });
    }
    this.setState({
      institutionId,
      showObservationsModal: !this.state.showObservationsModal,
    });
  };

  handleTableClick = (e, dataTable) => {
    let target = $(e.target);
    var current_row = target.parents("tr");
    if (current_row.hasClass("child")) {
      current_row = current_row.prev();
    }
    var data = dataTable.row(current_row).data();
    if (e.target.className.includes("delete-institution")) {
      if (window.confirm(`Deseja excluir a instituição ${data.name}?`))
        this.deleteInstitution(data.id);
    } else if (e.target.className.includes("toggle-institution")) {
      let stringMessage = `Deseja habilitar a instituição ${data.name}?`;
      if (data.active === true)
        stringMessage = `Deseja desabilitar a instituição ${data.name}?`;
      var confirm = window.confirm(stringMessage);
      if (confirm) this.toggleInstitution(data.id);
    } else if (e.target.className.includes("edit-institution")) {
      this.openInstitutionEditModal(data.id);
    } else if (e.target.className.includes("edit-observation")) {
      this.toggleObservationsModal(data.id);
    }
  };

  openInstitutionEditModal = (institutionId) => {
    this.setState({ institutionId }, this.toggleModal);
  };

  toggleInstitution = async (institutionId) => {
    try {
      await Api.put("/institution/toggle", { institution_id: institutionId });
      toast.success("Instituição atualizada.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  deleteInstitution = async (institutionId) => {
    try {
      await Api.delete("/institution", {
        data: { institution_id: institutionId },
      });
      toast.success("Instituição excluída.");
      this.getData(true);
    } catch (error) {
      if (error.response.data && error.response.data.userMessage)
        return toast.error(error.response.data.userMessage);
      if (error.response.data && error.response.data.message)
        return toast.error(error.response.data.message);
      return toast.error(error.message);
    }
  };

  toggleModal = () => {
    if (this.state.modalCreateOpen) {
      this.setState({ institutionId: null });
    }
    this.setState({ modalCreateOpen: !this.state.modalCreateOpen });
  };

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div className="mt-2 w-100">
        <button className="btn btn-primary mb-4" onClick={this.toggleModal}>
          CADASTRAR
        </button>
        {this.state.institutions.length === 0 ? (
          <div className="alert alert-info">
            Nenhuma instituição cadastrada.
          </div>
        ) : (
          <DataTable
            columns={columns}
            options={{
              buttons: [],
              responsive: true,
              columnDefs: columnDefs,
              processing: true,
              ajax: async (data, callback, settings) => {
                let { data: responseData } = await Api.get(
                  `/institution?page=${data.start / data.length + 1}&limit=${
                    data.length
                  }&search=${data.search?.value ?? ""}`
                );

                callback({
                  data: responseData.institutions,
                  currentPage: this.state.currentPage,
                  recordsTotal: responseData.count,
                  recordsFiltered: responseData.count,
                });
              },
              serverSide: true,
            }}
            handleActionClick={(e, dataTable) => {
              this.handleTableClick(e, dataTable);
            }}
            ref={(ref) => (this.table = ref)}
          />
        )}

        <Modal
          isOpen={this.state.modalCreateOpen}
          shouldCloseOnEsc={true}
          institutionId={
            this.state.institutionId ? this.state.institutionId : null
          }
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          onSuccess={(message) => {
            this.handleSuccess(message);
          }}
          onError={(message) => {
            this.handleError(message);
          }}
        />
        <ObservationModal
          isOpen={this.state.showObservationsModal}
          shouldCloseOnEsc={true}
          institutionId={this.state.institutionId}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleObservationsModal}
          onSuccess={(message) => {
            this.handleSuccess(message);
          }}
          onError={(message) => {
            this.handleError(message);
          }}
        />
      </div>
    );
  }
}
