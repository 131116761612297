import React, { Component } from "react";
import { Link } from "react-router-dom";

const AdminLink = ({ children, ...props }) =>
  localStorage.getItem("userType") === "admin" ? (
    <Link {...props}>{children}</Link>
  ) : null;


export default class Sidebar extends Component {
  render() {
    return (
      <div className="bg-light border-right" id="sidebar-wrapper">
        <div className="sidebar-heading">Painel</div>
        <div className="list-group list-group-flush">
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/app/students"
          >
            Estudantes
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/app/reports"
          >
            Relatório
          </Link>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/app/dashboard"
          >
            Dashboard
          </Link>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/institutions"
          >
            Institutições
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/course_categories"
          >
            Categorias
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/shifts"
          >
            Turnos
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/courses"
          >
            Cursos
          </AdminLink>
          <Link
            className="list-group-item list-group-item-action bg-light"
            to="/app/scholarships"
          >
            Bolsas
          </Link>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/impact"
          >
            Impacto Financeiro
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/faq"
          >
            FAQ
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/partners"
          >
            Parceiros
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/sellers"
          >
            Vendedores
          </AdminLink>
          <AdminLink
            className="list-group-item list-group-item-action bg-light"
            to="/app/users"
          >
            Usuários
          </AdminLink>
        </div>
      </div>
    );
  }
}
